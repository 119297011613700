export const formUser = {
  name: "Имя",
  firstName: "Имя",
  lastName: "Фамилия",
  businessName: "Название компании",
  country: "Страна",
  zipCode: "ПОЧТОВЫЙ ИНДЕКС",
  province: "Province",
  sectorInterest: "Сектор интересов",
  private: "Частный",
  company: "Компания",
  telephone: "Телефон",
  repeatPwd: "Повторить пароль",
  authorizeGdpr:
    "Я разрешаю обработку моих персональных данных в соответствии с Информацией об обработке персональных данных для клиентов и поставщиков в соответствии с",
  textLinkGdpr:
    "статьей 13 Законодательного декрета № 196/2003 и Европейским регламентом ER № 679/2016.",
  subscribeToNewsletter:
    "I хотите быть в курсе новостей Alcea через информационный бюллетень ",
  textLinkNewsletterGdpr: "арт. 13 Европейского Регламента № 679/2016",
  authorizeCommercial:
    "Я разрешаю использовать мои личные данные для исследования рынка",
  notificationMail:
    "Вы еще не включили уведомление о входе по электронной почте!<br/> Хотите включить сейчас?<br/>< br/> <b> Примечание. Вы можете изменить эту настройку в своем профиле в любое время</b>",
};
