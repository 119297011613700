import http from "@/util/http-common";
import store from "@/store/index";

class CustomerProfileService {
    getUrlNoParam() {

        return store.getters.getUserRole === "IT"
            ? "v1/public/it" :
            store.getters.getUserRole === "ADMIM"
                ? "v1/public/admin" :
                store.getters.getUserRole === "COMMERCIAL"
                    ? "v1/public/commercial" :
                    store.getters.getUserRole === "AGENT"
                        ? "v1/public/agent" :
                        store.getters.getUserRole === "ALCEA"
                            ? "v1/public/alcea" :
                            store.getters.getUserRole === "CUSTOMER"
                                ? "v1/public/customer"
                                : "v1/public/user";
    }

    autocompleteCustomerProfile(customerSearcbString) {
        let url = this.getUrlNoParam() + "/customer-profiles/search/autocomplete?"
        url = this._addParam(url, 'searchString', customerSearcbString)
        return http.get(url);
    }

    _addParam(url, paramName, paramValue) {
        if (url.slice(-1) !== '?') {
            return url + (this.isNotEmpty(paramValue) ? "&" + paramName + "=" + paramValue : "")
        }
        return url + (this.isNotEmpty(paramValue) ? paramName + "=" + paramValue : "")
    }

    isEmpty(val) {
        return !val
    }

    isNotEmpty(val) {
        return !this.isEmpty(val)
    }
}

export default new CustomerProfileService();
