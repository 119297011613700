export const sds = {
    label: {
        findSDS: "Cerca SDS",
        downloadOrSend: "Scarica o Invia"
    },
    form: {
        site: "SITO",
        allSites: "Tutti i siti",
        serial: "SERIE",
        color: "COLORE",
        product: "PRODOTTO",
        customerCode: "CLIENTE",
        year: "ANNO",
        batch: "LOTTO",
        orderCode: "CODICE ORDINE",
        language: "LINGUA",
        selectLanguage: "Seleziona una lingua",
    },
    results: {
        yearMonth: 'Anno-Mese',
        batch: 'Lotto',
        product: 'Prodotto',
        recipe: 'Ricetta',
        versionOrBarra: 'Versione / Barra',
        noResults: "Non sono stati trovati risultati"
    },
    disclaimer: {
        batch: "Iniziare a compilare il campo di seguito con il numero del lotto desiderato (sia formato Glink che formato SAGE X3) per vedere i risultati della ricerca, selezionare il lotto per visualizzare le SDS per le lingue disponibili.",
        customer: "I risultati di ricerca fanno sempre riferimento ad un lotto di produzione legato ad un cliente, i lotti legati ad un cliente sono solo nel formato nuovo di SAGE X3 (dopo il 14/10/2024).",
        product: "Questa ricerca permette di trovare anche prodotti non legati a un lotto (sia Glink che SAGE)",
        document: "Questa ricerca permette di trovare le SDS relative ad un codice ordine (SAGE X3)",
        warehouse: "I risultati di ricerca fanno sempre riferimento ad un lotto di produzione, i lotti possono anche essere in formato vecchio (precedenti al 14/10/2024). <br/> Se si compila il campo Cliente verranno trovati solo i lotti successivi al cambio di sistema.",
        sendSds: "Inserisci nel form sottostante uno o più indirizzi email a cui inviare la Scheda di Sicurezza,\n oppure clicca su SCARICA per effettuare il download del file."
    }
};
