export const sds = {
    label: {
        findSDS: "Find SDS",
        downloadOrSend: "Download or Send"
    },
    form: {
        site: "PRODUCTION SITE",
        allSites: "All sites",
        serial: "SERIES",
        color: "COLOR",
        product: "PRODUCT",
        customerCode: "CUSTOMER",
        year: "YEAR",
        batch: "BATCH",
        orderCode: "ORDER CODE",
        language: "LANGUAGE",
        selectLanguage: "Select a language",
    },
    results: {
        yearMonth: 'Year-Month',
        batch: 'Batch',
        product: 'Product',
        recipe: 'Recipe',
        versionOrBarra: 'Version / Barra',
        noResults: "No results found"
    },
    disclaimer: {
        batch: "Please start filling in the field below with the desired batch number (either Glink or SAGE X3 format) to see search results, select the batch to view SDSs for available languages.",
        customer: "Search results always refer to a production batch linked to a customer, batches linked to a customer are only in the new SAGE X3 format (after 10/14/2024).",
        product: "This search allows you to find products not linked to a batch (both Glink and SAGE)",
        document: "This search allows to find the SDS relating to an order code (SAGE X3)",
        warehouse: "Search results always refer to a production batch, batches can also be in old format (prior to 10/14/2024).<br/> When filling in the Customer field, only batches following the system change will be found.",
    }
};
