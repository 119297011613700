<template>
  <b-container fluid class="p-3">
    <b-row>
      <b-col>
        <feedback-alert
            ref="sds-feedback"
            class="w-100"
            :status="feedbackStatus"
            :message="feedbackMessage"
            @clearStatus="clearStatus">
        </feedback-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-alert v-html="$t('sds.disclaimer.document')" variant="warning" show></b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <sds-filter
            :sds-search-name="formFilterName"
            :filter-form.sync="filterForm"
            @submit-search="submitSearch"
        ></sds-filter>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
      <!-- document info -->
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-right">
      <!-- Download All -->
        <b-btn v-if="showSearchResults" @click="openSendAllModal()">Invia tutte</b-btn>
        <b-modal
            size="lg"
            scrollable
            centered
            :title="'Invia tutte'"
            no-close-on-backdrop
            ref="downlaodAll"
        >
          <b-container fluid >
            <b-overlay :show="isLoading" rounded="sm">
              <b-row >
                <b-col class="pb-4">
                  verranno inviate tutte le SDS relative a questo ordine agli indirizzi indicati e in copia consocenza anche a te. A seconda del numero di SDS da inviare la procedura potrebbe richiedere alcuni minuti
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group
                      :label="$t('sds.form.language')"
                      :invalid-feedback="!langSelected ? 'Selezionare una lingua' : null"
                  >
                    <b-form-select
                        v-model="langSelected"
                        :state="!!langSelected"
                    >
                      <b-select-option
                          :value="null"
                          selected>
                        {{ $t('sds.form.selectLanguage') }}
                      </b-select-option>
                      <b-select-option v-for="lang in languages" :value="lang.value" :key="lang.value">
                        {{ $t(lang.text) }}
                      </b-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="p-2" v-for="(e, index) in emailAddresses" :key="index" cols="12">
                  <b-input-group >
                    <b-form-input
                        required
                        :placeholder="'Email'"
                        v-model="emailAddresses[index].email"
                        :state="emailAddresses[index].valid"
                        @change="validateEmails"
                    />
                    <b-input-group-append>
                      <b-button v-if="emailAddresses.length > 1" @click="removeEmail(index)" variant="primary">-</b-button>
                      <b-button v-if="index === (emailAddresses.length - 1)" @click="addEmail" variant="primary">+</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-overlay>
          </b-container>
          <template #modal-footer>
            <button v-b-modal.modal-close_visit :disabled="isLoading" @click="closeModal()" class="btn btn-default m-1">Annulla</button>
            <button v-b-modal.modal-close_visit :disabled="isLoading" @click="sendAllSds()" class="btn btn-primary m-1">Invia</button>
          </template>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <sds-search-results
            v-if="showSearchResults"
            :sds-results="sdsResults"
            :sds-search-name="formFilterName"
            :is-loading="sdsResultsIsLoading"
            @search-languages="searchLanguages"
        ></sds-search-results>
      </b-col>
    </b-row>
    <b-row>
      <b-col ref="sds-languages-wrap">
        <sds-lang-availables
            ref="sds-lang-component"
            v-if="showSearchLanguages"
            :lang-availables="languagesCheck"
            :is-loading="languagesCheckIsLoading"
            @download-sds="downloadSds"
            @send-sds="sendSds"
            @anchor-to-feedback="anchorToFeedback"
        ></sds-lang-availables>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import FeedbackAlert from "@/components/feedback-alert.vue";
import AdwButton from "@/components/global-components/adw-button";
import ErrorManager from '@/services/errors-service/errors-manager';
import SdsFilter from "@/components/sds/sds-filter";
import SdsSearchResults from "@/components/sds/sds-search-results";
import SdsLangAvailables from "@/components/sds/sds-lang-availables";
import ProductService from "@/services/api-services/product-service";
import {sdsLanguages} from '@/commons/sds-languages';

export default {
  name: "SdsSearchDocument",


  data() {
    return {
      languages: sdsLanguages,
      formFilterName: 'searchDocument',
      showSearchResults: false,
      sdsResults: [],
      sdsResultsIsLoading: false,
      showSearchLanguages: false,
      languagesCheck: {},
      languagesCheckIsLoading: false,
      currentRequest: null,
      emailAddresses: [
        {email: "", valid: false}
      ],
      langSelected: null,
      filterForm: {
        site: null,
        serial: null,
        color: null,
        customerCode: null,
        orderCode: null,
        year: null,
      },
      feedbackStatus: null,
      feedbackMessage: "",
    }
  },

  components: {
    SdsFilter,
    SdsSearchResults,
    SdsLangAvailables,
    FeedbackAlert,
    AdwButton,
  },

  methods: {
    clearStatus() {
      this.feedbackStatus = null;
      this.feedbackMessage = "";
    },
    removeEmail(index) {
      this.emailAddresses.splice(index, 1);
    },
    addEmail() {
      this.emailAddresses.push({email: "", valid: true})
    },
    validateEmails () {
      this.emailAddresses.forEach(e => {
        e.valid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(e.email);
      });
    },
    closeModal() {
      this.emailAddresses = [{email: "", valid: true}];
      this.langSelected = null;
      this.download = true;
      this.$refs['downlaodAll'].hide();
      // Anchor to top
      this.$emit('anchor-to-feedback');
    },
    openSendAllModal() {
      this.$refs['downlaodAll'].show();
    },

    submitSearch() {
      this.showSearchResults = true;
      this.sdsResultsIsLoading = true;
      this.showSearchLanguages = false;
      this.$forceUpdate();

      ProductService.findSdsByOrder(this.filterForm)
          .then((result) => {
            this.sdsResults = result.data;
          }).catch((error) => {
        console.error(error)
      }).finally(() => {
        this.sdsResultsIsLoading = false;
        this.$forceUpdate();
      })
    },

    searchLanguages(item) {
      console.log(item);
      this.showSearchLanguages = true;
      this.currentRequest = {
        site: item.site,
        batch: item.batch,
        serial: item.serial,
        color: item.color,
        recipe: item.recipe,
        version: item.version,
        isOldSds: item.isOldSds,
        isUsaOrCa: false
      }

      this.languagesCheckIsLoading = true;
      let element = this.$refs['sds-languages-wrap'];
      const top = element.offsetTop;
      window.scrollTo(0, top);

      this.$forceUpdate();
      ProductService.checkLanguages(this.currentRequest)
          .then((result) => {
            this.languagesCheck = result.data;
          }).catch((error) => {
        console.error(error)
        this.languagesCheck = {};
        this.feedbackStatus = "FailDismissable"
        this.feedbackMessage = "Errore nel recupero delle lingue disponibili";//this.$i18n.t("UnauthorizedOrInvalid")
      }).finally(() => {
        this.languagesCheckIsLoading = false;
        this.$forceUpdate();
      })
    },

    sendSds (sendSdsObj) {
      console.log("sendSds", sendSdsObj)
      this.languagesCheckIsLoading = true;
      this.$forceUpdate();

      ProductService.sendSds(
          Object.assign({emails: sendSdsObj.emails}, {language: sendSdsObj.language}, {orderCode: this.filterForm.orderCode}, this.currentRequest)
      )
          .then((response) => {
            this.feedbackStatus = "Success";
            this.feedbackMessage = "sdsSentSuccess";
          }).catch((error) => {
        console.error(error)
        this.feedbackStatus = "FailDismissable"
        this.feedbackMessage = "sdsSentError";//this.$i18n.t("UnauthorizedOrInvalid")
      }).finally(() => {
        this.languagesCheckIsLoading = false;
        this.$refs['sds-lang-component'].closeModal();
        this.$forceUpdate();
      })
    },
    sendAllSds () {
      console.log("sendAllSds")
      this.languagesCheckIsLoading = true;
      this.$forceUpdate();
      this.validateEmails();
      if(this.emailsAreValid && this.langSelected) {
        ProductService.sendAllSds(
            Object.assign({emails: this.emailAddresses.map(e => e.email)}, {language: this.langSelected}, {orderCode: this.filterForm.orderCode}, {isUsaOrCa: false})
        )
            .then((response) => {
              this.feedbackStatus = "Success";
              this.feedbackMessage = "sdsSentSuccess";
            }).catch((error) => {
          console.error(error)
          this.feedbackStatus = "FailDismissable"
          this.feedbackMessage = "sdsSentError";//this.$i18n.t("UnauthorizedOrInvalid")
        }).finally(() => {
          this.languagesCheckIsLoading = false;
          this.closeModal();
          this.$forceUpdate();
        })
      }
    },
    downloadSds(language) {
      this.languagesCheckIsLoading = true;
      this.$forceUpdate();

      ProductService.downloadSds(Object.assign({language}, this.currentRequest))
          .then((response) => {

            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
            link.download = 'SDS-' + this.currentRequest.serial + this.currentRequest.color + '-' + language + '.pdf'
            document.body.appendChild(link)

            // click the link to download the pdf
            link.click()

            // remove the link
            document.body.removeChild(link)

          }).catch((error) => {
        console.error(error)
        this.feedbackStatus = "FailDismissable"
        this.feedbackMessage = "Errore nel Download della scheda di sucurezza";//this.$i18n.t("UnauthorizedOrInvalid")
      }).finally(() => {
        this.languagesCheckIsLoading = false;
        this.$refs['sds-lang-component'].closeModal();
        this.$forceUpdate();
      })
    },
    anchorToFeedback () {
      let element = this.$refs['sds-feedback'];
      let top = element ? element.offsetTop : 0;
      window.scrollTo(0, top);
    }
  },

  computed: {
    ...mapState({
      customerInfo: (state) => state.customer.customerinfo,
      userType: (state) => state.auth.user.type,
    }),

    isUserAgentOrCommercial() {
      return this.$store.getters.getUserRole === "AGENT" || this.$store.getters.getUserRole === "COMMERCIAL"
    },
    emailsAreValid () {
      return this.emailAddresses.reduce((lastValidity, currentValid) => lastValidity && currentValid.valid,
          true,)
    },
  },
}
</script>
