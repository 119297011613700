<template>
  <b-navbar toggleable="lg" ref="site-navbar" class="adw-navbar">

    <!-- logo -->
    <b-navbar-brand class="adw-navbar-brand adw-pointer" @click.stop="$router.push('/')">
      <img src="@/assets/img/ADW_Logo_B.jpg" alt="ADW" />
      <span class="d-none d-sm-inline">Alcea Digital Web</span>
    </b-navbar-brand>

    <!-- collapsable for mobile -->
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <!-- menu items -->
    <b-collapse id="nav-collapse" is-nav class="pr-0">
      <!-- filler -->
      <div class="adw-nav-filler"></div>
      
      <b-navbar-nav>
        <b-nav-text v-if="loggedIn" >
          <span v-if="showUsername">{{ $t("titles.welcomeUser", {name: user.firstname + ' ' + user.lastname}) }}</span>
          <router-link v-else to="/profile-customer">{{ $t("titles.welcomeCustomer", {name: customerinfo.businessName}) }}</router-link>
        </b-nav-text>
        <b-nav-item to="/faq" :class="isSelected('Faq')" >
          <h2>{{ $t("titles.faq") }}</h2>
        </b-nav-item>
        <!-- link: product search -->
        <b-nav-item to="/products-search" :class="isSelected('ProductsSearch')"  v-if="loggedIn">
          <h2>{{ $t("titles.productSearch") }}</h2>
        </b-nav-item>

        <!-- link: private area -->
        <b-nav-item :to="customerinfo && customerinfo.customerCode ? '/profile-customer' : isUserAgentOrCommercial ? '/profile-agent' : '/profile-user'" :class="isSelected('PrivateArea')"  v-if="loggedIn">
          <h2>{{ $t("titles.privateArea") }}</h2>
        </b-nav-item>

        <!-- locale-switcher -->
        <b-nav-form class="local-switcher-nav-item">
          <locale-switcher/>
        </b-nav-form>

        <!-- user menu -->
        <b-nav-item @click.stop="logout" v-if="loggedIn">
          <h2>{{ $t("actions.logout") }}</h2>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapState } from 'vuex'
import LocaleSwitcher from '@/components/global-components/local-switcher.vue'
import i18n from '@/plugins/i18n'

export default {
  name: 'app-navbar',

  components: {
    LocaleSwitcher
  },

  data() {
    return {
      i18n,
    }
  },

  computed: {
    ...mapState({
      loggedIn: (state) => state.auth.status.loggedIn,
      user: (state) => state.user.userinfo,
      userRole: (state) => state.auth.user.role,
      userType: (state) => state.auth.user.type,
      loggedInAsCustomer: (state) => state.customer.status.loggedInAsCustomer,
      customerinfo: (state) => state.customer.customerinfo,
    }),

    isUserAgentOrCommercial() {
      return this.$store.getters.getUserRole === "AGENT" || this.$store.getters.getUserRole === "COMMERCIAL"
    },

    getUserLanguage() {
      const brwLang = navigator.language || navigator.userLanguage
      if (this.$store.getters.getUser && this.$store.getters.getUser.language) {
        return this.$store.getters.getUser.language.substring(0, 2)
      } else if (brwLang) {
        return brwLang.substring(0, 2)
      }
      return 'it'
    },

    showUsername() {
      return !this.isUserAgentOrCommercial || !this.customerinfo || !this.customerinfo.customerCode;
    }
  },

  methods: {
    isSelected(sector) {
      return this.$route.meta && this.$route.meta.sector === sector ? 'selected' : '';
    },

    logout() {
      this.$store.dispatch('logout').then(() => this.$router.push('/'))
    },
  },

  created() {
    this.$i18n.locale = this.getUserLanguage;

    this.$root.$on('scrollToElement', (topValue) => {
      var header = this.$refs['site-navbar'].$el;
      let top = topValue - header.clientHeight;
      //console.log('header.clientHeight', header.clientHeight)
      window.scrollTo(0, top);
    });
  },

  mounted() {
    if (this.userRole === 'USER' && this.userType === 'C') {
      this.$store
          .dispatch('getInvitationsUser', { page: '0', row: '1' })
          .catch((e) => console.error(e))
    }
    console.debug('customerinfo', this.customerinfo)
  },

  watch: {
    getUserLanguage(newLang, oldLag) {
      this.$i18n.locale = newLang
    },

    $route(to, from) {
      if (this.userRole === 'USER' && this.userType === 'C') {
        this.$store
            .dispatch('getInvitationsUser', { page: '0', row: '1' })
            .catch((e) => console.error(e))
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/custom-vars.scss";

// NAVBAR

.adw-navbar {
  height: 60px;
  border-bottom: var(--border);
  padding: 0 !important;

  align-items: stretch;

  .navbar-collapse {
    align-items: stretch;
  }

  .navbar-text,
  .nav-item {
    border-bottom: var(--border);
    background-color: white;

    .nav-link,
    > span
    > a {
      padding: 10px 15px;
    }

    &:hover,
    &.selected {
      background-color: var(--yellow);
    }

    &.navbar-text {
      background-color: white;
    }
  }

  .local-switcher-nav-item {
    display: block;
    padding: 10px 15px;
    border-bottom: var(--border);
    background-color: white;
  }
}

.adw-nav-filler {
  margin-left: auto;
}

.adw-navbar-brand img {
  height: 50px;
}

@include respond-above(sm) {
  .adw-navbar-brand {
    padding-top: 10px;
    padding-left: 20px;

    img {
      width: 182px;
      height: auto;
    }
  }

  .adw-navbar {
    height: 100px;
    border: var(--border);
    padding: 0 !important;

    align-items: stretch;

    .navbar-text,
    .nav-item {
      border-left: var(--border);
      border-bottom: none;
      background-color: white;

      display: flex;
      align-content: center;

      .nav-link {
        display: flex;
        align-content: center;
        align-items: center;
        padding-left: 30px;
        padding-right: 30px;
      }

    }

    .navbar-text {
      border: none;
      > span,
      > a {
        font-size: $font-size-base;
        color: $body-color;
        display: flex;
        align-content: center;
        align-items: center;
        padding-right: 30px;
      }
    }

    .local-switcher-nav-item {
      border-left: var(--border);
      border-bottom: none;
      background-color: white;
      padding-left: 30px;
      padding-right: 30px;
      display: flex;
      align-content: center;
      align-items: center;
      
      select {
        border: none;
        box-shadow: none;
        font-size: 1.25rem;
      }
    }
  }
}

.adw-navbar .nav-item {
  cursor: pointer;
}

.adw-navbar .navbar-nav .nav-link {
  color: black;
}

.adw-navbar .nav-item select {
  margin-top: -10px;
  border: none;
  box-shadow: none;
  font-size: 21px;
  font-weight: 500;
}

.navbar-toggler {
  align-self: center;
}
</style>
