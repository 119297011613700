<template>

  <b-container class="my-4 px-0" fluid>
  <b-row>
    <b-col>
      <b-table
          :items="sdsResults"
          :keys="fields"
          :fields="fields"
          :busy="isLoading"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :tbody-tr-class="rowClass"
          class="mb-0"
          ref="dataTable"
          stacked="sm"
          hover
          show-empty>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #empty="">
          <h5 class="text-center">{{ $t('sds.results.noResults') }}</h5>
        </template>

        <template #cell(productCode)="data">
          <span>{{ data.item.serial + "-" + data.item.color }}</span>
        </template>
        <template #cell(date)="data">
          <span>{{ data.item.year }}-{{ data.item.month }}</span>
        </template>
        <template #cell(clpGhs)="data">

        </template>
        <template #cell(actions)="data">
          <b-button variant="primary" class="mb-2" @click="searchLanguages(data.item)">
            <b-icon-search aria-hidden="true"></b-icon-search>
          </b-button>

        </template>
      </b-table>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
      ></b-pagination>
    </b-col>
  </b-row>
  </b-container>
</template>

<script>
import AdwButton from "@/components/global-components/adw-button";
export default {
  name: "SdsSearchResults",
  components: {
    AdwButton
  },

  data() {
    return {
      perPage: 10,
      currentPage: 1,
      sortBy: 'date',
      sortDesc: false,
    }
  },

  props: {
    sdsSearchName: String,
    sdsResults: Array,
    isLoading: Boolean
  },

  computed: {
    rows() {
      return this.sdsResults.length
    },
    fields() {
      switch (this.sdsSearchName) {
        case 'searchWarehouse':
        case 'searchCustomer':
        case 'searchDocument':
          return [
            {
              key: "date",
              label: this.$i18n.t("sds.results.yearMonth"),
              sortKey: "date",
              sortable: true,
            },
            {
              key: "batch",
              label: this.$i18n.t("sds.results.batch"),
              sortKey: "batch",
              sortable: true,
            },
            {
              key: "productCode",
              label: this.$i18n.t("sds.results.product"),
              sortKey: "productCode",
              sortable: true,
            },
            /*{
              key: "clpGhs",
              label: this.$i18n.t("tipo"),
            },*/
            {
              key: "actions",
              label: '',
              sortKey: "productCode",
            },
          ];
        case 'searchProduct':
          return [
            {
              key: "productCode",
              label: this.$i18n.t("sds.results.product"),
              sortKey: "productCode",
              sortable: true,
            },
            {
              key: "recipe",
              label: this.$i18n.t("sds.results.recipe"),
              sortKey: "recipe",
              sortable: true,
            },
            {
              key: "version",
              label: this.$i18n.t("sds.results.versionOrBarra"),
              sortKey: "version",
              sortable: true,
            },
            {
              key: "actions",
              label: ''
            },
          ];
      }
    },
  },

  methods: {
    searchLanguages(item) {
      console.log('searchLanguages', item, Object.assign({date:this.formatDateForApiCall(item.date)}, {isUsaOrCa: false}))
      this.sdsResults.forEach(i => i.selected = false);
      item.selected = true;
      this.$refs.dataTable.refresh()
      this.$emit('search-languages', Object.assign({date:this.formatDateForApiCall(item.date)}, {isUsaOrCa: false}, item))
    },
    formatDate (date) {
      let dateObj = new Date(date);
      return dateObj.getFullYear() + '-' + (dateObj.getMonth()+1);
    },
    formatDateForApiCall (date) {
      if(date) {
        let dateObj = new Date(date);
        return dateObj.getFullYear() + '-' + (dateObj.getMonth()+1) + '-' + dateObj.getDate();
      }

      return null;
    },
    rowClass(item, type) {
      console.log('rowClass', item, type)
      if (!item || type !== 'row') return
      if (item.selected === true) return 'table-active'
    }
  }


}
</script>

<style scoped>

</style>