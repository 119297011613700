import { render, staticRenderFns } from "./sds-lang-availables.vue?vue&type=template&id=567535a7&scoped=true&"
import script from "./sds-lang-availables.vue?vue&type=script&lang=js&"
export * from "./sds-lang-availables.vue?vue&type=script&lang=js&"
import style1 from "./sds-lang-availables.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "567535a7",
  null
  
)

export default component.exports