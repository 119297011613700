<template>
  <b-container fluid class="p-3">
    <b-row>
      <b-col>
        <feedback-alert
            ref="sds-feedback"
            class="w-100"
            :status="feedbackStatus"
            :message="feedbackMessage"
            @clearStatus="clearStatus">
        </feedback-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-alert v-html="$t('sds.disclaimer.batch')" variant="warning" show></b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group :label="$t('sds.form.batch')" description="L'autocompletamento mostra solo i primi 20 risultati" :invalid-feedback="validationError('batch')">
          <b-input-group>
            <vue-bootstrap-autocomplete
                class="form-control p-0"
                v-model="batchSearchString"
                :ieCloseFix="false"
                :data="batchResults"
                :serializer="(r) => r.batch"
                @hit="searchLanguages($event)"
                :placeholder="$t('sds.form.batch')"
                @input="updateBatchDataset"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col ref="sds-languages-wrap">
        <sds-lang-availables
            ref="sds-lang-component"
            v-if="showSearchLanguages"
            :lang-availables="languagesCheck"
            :is-loading="languagesCheckIsLoading"
            @download-sds="downloadSds"
            @send-sds="sendSds"
            @anchor-to-feedback="anchorToFeedback"
        ></sds-lang-availables>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import FeedbackAlert from "@/components/feedback-alert.vue";
import AdwButton from "@/components/global-components/adw-button";
import ErrorManager from '@/services/errors-service/errors-manager';
import SdsLangAvailables from "@/components/sds/sds-lang-availables";
import ProductService from "@/services/api-services/product-service";
import VueBootstrapAutocomplete from '@vue-bootstrap-components/vue-bootstrap-autocomplete';

export default {
  name: "SdsSearchBatch",



  data() {
    return {
      formFilterName: 'searchBatch',
      batchSearchString: "",
      batchResults: [],
      showSearchLanguages: false,
      languagesCheck: {},
      languagesCheckIsLoading: false,
      validation: [],
      batchSelected: null,
      filterForm: {
        batch: null,
        isUsaOrCa: false
      },
      feedbackStatus: null,
      feedbackMessage: "",
    }
  },
//241001000025

  components: {
    FeedbackAlert,
    AdwButton,
    SdsLangAvailables,
    VueBootstrapAutocomplete
  },

  methods: {
    updateBatchDataset () {
      this.filterForm.batch = null;
      if(this.batchSearchString.length > 2) {
        ProductService.autocompleteBatch(this.batchSearchString)
            .then((result) => {
              this.batchResults = result.data;
              this.$forceUpdate();
            })
            .catch((error) => {
              console.error(error)
              this.feedbackStatus = "FailDismissable"
              this.feedbackMessage = "sdsUnableToRetrieveBatch";//this.$i18n.t("UnauthorizedOrInvalid")
            });
      }
    },

    clearStatus() {
      this.feedbackStatus = null;
      this.feedbackMessage = "";
    },

    searchLanguages(batchResult) {
      console.log(batchResult);
      this.showSearchLanguages = true;
      this.currentRequest = {
        site: batchResult.site,
        batch: batchResult.batch,
        serial: batchResult.serial,
        color: batchResult.color,
        recipe: batchResult.recipe,
        version: batchResult.version,
        isOldSds: batchResult.isOldSds,
        isUsaOrCa: false
      }

      this.languagesCheckIsLoading = true;
      this.$forceUpdate();
      let element = this.$refs['sds-languages-wrap'];
      const top = element.offsetTop;
      window.scrollTo(0, top);


      ProductService.checkLanguages(this.currentRequest)
          .then((result) => {
            this.languagesCheck = result.data;
          }).catch((error) => {
            console.error(error)
            this.languagesCheck = {};
            this.feedbackStatus = "FailDismissable"
            this.feedbackMessage = "sdsUnableToRetrieveLanguages";//this.$i18n.t("UnauthorizedOrInvalid")
          }).finally(() => {
            this.languagesCheckIsLoading = false;
            this.$forceUpdate();
          });
    },

    sendSds (sendSdsObj) {
      console.log("sendSds", sendSdsObj)
      this.languagesCheckIsLoading = true;
      this.$forceUpdate();

      ProductService.sendSds(
          Object.assign({emails: sendSdsObj.emails}, {language: sendSdsObj.language}, this.currentRequest)
      )
        .then((response) => {
          this.feedbackStatus = "Success";
          this.feedbackMessage = "sdsSentSuccess";
        }).catch((error) => {
          console.error(error)
          this.feedbackStatus = "FailDismissable"
          this.feedbackMessage = "sdsSentError";//this.$i18n.t("UnauthorizedOrInvalid")
        }).finally(() => {
          this.languagesCheckIsLoading = false;
          this.$refs['sds-lang-component'].closeModal();
          this.$forceUpdate();
        })
    },

    downloadSds(language) {
      this.languagesCheckIsLoading = true;
      this.$forceUpdate();

      ProductService.downloadSds(Object.assign({language}, this.currentRequest))
          .then((response) => {

            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
            link.download = 'SDS-' + this.currentRequest.serial + this.currentRequest.color + '-' + language + '.pdf'
            document.body.appendChild(link)

            // click the link to download the pdf
            link.click()

            // remove the link
            document.body.removeChild(link)

          }).catch((error) => {
        console.error(error)
        this.feedbackStatus = "FailDismissable"
        this.feedbackMessage = "sdsDownloadError";//this.$i18n.t("UnauthorizedOrInvalid")
      }).finally(() => {
        this.languagesCheckIsLoading = false;
        this.$refs['sds-lang-component'].closeModal();
        this.$forceUpdate();
      })
    },

  validationState(contextRef) {
    return this.validation.find(
        (invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()
    )
        ? false
        : null;
  },
  validationError(contextRef) {
    if (this.validation.length > 0) {
      let find =
          this.validation.find(
              (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
          ) || null;
      return find ? this.$i18n.t(find.error) : null;
    }
  },
  validateCurretForm() {
    this.validation = Validator.validateForm(this.formFilterName, this.filterForm).invalid;
    console.log(this.validation);
    if (this.validation.length > 0 ||
        Object.keys(this.filterForm).find((f) => {
          return this.validation[f] ? this.checkValidation(f) : false;
        })
    ) {
      return false;
    } else {
      return true;
    }
  },
    anchorToFeedback () {
      let element = this.$refs['sds-feedback'];
      let top = element ? element.offsetTop : 0;
      window.scrollTo(0, top);
    }

  },

  computed: {
    ...mapState({
      customerInfo: (state) => state.customer.customerinfo,
      userType: (state) => state.auth.user.type,
    }),

    isUserAgentOrCommercial() {
      return this.$store.getters.getUserRole === "AGENT" || this.$store.getters.getUserRole === "COMMERCIAL"
    },
  },
}
</script>
