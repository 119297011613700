
<template>
    <b-container fluid>
      <b-overlay :show="isLoading" rounded="sm">
      <b-row>
        <b-col cols="4" sm="3" md="1"  v-for="l in languages" :key="l.value" >
          <span
              v-b-tooltip.hover
              :title="langAvailables[l.value] ? 'Download SDS ' + l.value  : 'SDS non trovata'">
            <b-button @click="selectLanguage(l.value)"
                      :id="'sds-language-' + l.value"
                      variant="link"
                      :disabled="!langAvailables[l.value]"
                      :class="'sds-lang-btn ' + (langAvailables[l.value] ? 'present' : 'not-present')">
              <img :src="getPathImg(l.value)"  :alt="'Download SDS ' + l.value "/>
              <!--{{ $t(l.text) }}-->
            </b-button>
          </span>
        </b-col>
      </b-row>

        <b-modal
            size="lg"
            scrollable
            centered
            :title="$t('sds.label.downloadOrSend')"
            no-close-on-backdrop
            ref="selectLanguageModal"
        >
          <b-container fluid >
            <b-overlay :show="isLoading" rounded="sm">
            <b-row >
              <b-col class="pb-4">
                {{ $t('sds.disclaimer.sendSds') }}
              </b-col>
            </b-row>

            <b-row>
              <b-col class="p-2" v-for="(e, index) in emailAddresses" :key="index" cols="12">
                <b-input-group >
                  <b-form-input
                      required
                      :placeholder="'Email'"
                      v-model="emailAddresses[index].email"
                      :state="emailAddresses[index].valid"
                      @change="validateEmails"
                  />
                  <b-input-group-append>
                    <b-button v-if="emailAddresses.length > 1" @click="removeEmail(index)" variant="primary">-</b-button>
                    <b-button v-if="index === (emailAddresses.length - 1)" @click="addEmail" variant="primary">+</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
            </b-overlay>
          </b-container>
          <template #modal-footer>
            <button v-b-modal.modal-close_visit :disabled="isLoading" @click="closeModal()" class="btn btn-default m-1">{{ $t('actions.cancel')}}</button>
            <button v-b-modal.modal-close_visit :disabled="isLoading" @click="downloadSds()" class="btn btn-primary m-1">{{ $t('actions.download')}}</button>
            <button v-b-modal.modal-close_visit :disabled="isLoading" @click="sendSds()" class="btn btn-primary m-1">{{ $t('actions.send')}}</button>
          </template>
        </b-modal>
      </b-overlay>
    </b-container>

</template>

<script>
import {sdsLanguages} from '@/commons/sds-languages';

export default {
  name: "SdsLangAvailables",

  data() {
    return {
      languages: sdsLanguages,
      emailAddresses: [
        {email: "", valid: false}
      ],
      langSelected: null,
      download: true,
      customerEmails: this.defaultEmails
    }
  },

  components: {

  },

  created() {
    this.customerEmails.forEach(e => {
      this.emailAddresses.push({email: e, valid: true});
    })
  },

  props: {
    langAvailables: Object,
    defaultEmails: Array,
    isLoading: Boolean,
  },

  computed: {
    emailsAreValid () {
      return this.emailAddresses.reduce((lastValidity, currentValid) => lastValidity && currentValid.valid,
          true,)
    }
  },

  methods: {
    getPathImg(name) {
      try {
        const images = require.context('@/assets/flags/', false, /\.png$/)
        return images("./" + name + ".png")
      } catch (err) {
        return ''
      }
    },
    selectLanguage(lang) {
      this.langSelected = lang;
      this.$refs['selectLanguageModal'].show();
    },
    removeEmail(index) {
      this.emailAddresses.splice(index, 1);
    },
    addEmail() {
      this.emailAddresses.push({email: "", valid: true})
    },
    closeModal() {
      this.emailAddresses = [{email: "", valid: true}];
      this.langSelected = null;
      this.download = true;
      this.$refs['selectLanguageModal'].hide();
      // Anchor to top
      this.$emit('anchor-to-feedback');
    },
    validateEmails () {
      this.emailAddresses.forEach(e => {
        e.valid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(e.email);
      });
    },
    sendSds () {
      console.log("sendSds")
      this.validateEmails();
      if(this.emailsAreValid)
        this.$emit('send-sds', {language: this.langSelected, emails: this.emailAddresses.map(e => e.email)});
      else
        console.error("emails not valid")
    },
    downloadSds(){
      this.$emit('download-sds', this.langSelected);
    }
  }


}
</script>

<style scoped>

</style>


<style lang="scss">

  .sds-lang-btn {
    max-width: 100%;

    img {
      max-width: 100%;
      width: 80px;
      height: auto;
    }
    &.present {

    }

    &.not-present {
      opacity: 0.4;
    }
  }

</style>