export const sds = {
    form: {
        site: "PRODUCTION SITE",
        allSites: "All sites",
        serial: "SERIES",
        color: "COLOR",
        product: "PRODUCT",
        customerCode: "CUSTOMER",
        year: "YEAR",
        batch: "BATCH",
        orderCode: "ORDER CODE",
        language: "LANGUAGE",
        selectLanguage: "Select a language",
    },
    results: {
        yearMonth: 'Year-Month',
        batch: 'Batch',
        product: 'Product',
        recipe: 'Recipe',
        versionOrBarra: 'Version / Barra',
    }
};
