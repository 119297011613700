<template>
  <b-container fluid class="p-3">
  <b-row>
    <b-col>
      <feedback-alert
          ref="sds-feedback"
          class="w-100"
          :status="feedbackStatus"
          :message="feedbackMessage"
          @clearStatus="clearStatus">
      </feedback-alert>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <b-alert v-html="$t('sds.disclaimer.customer')" variant="warning" show></b-alert>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <sds-filter
          :sds-search-name="formFilterName"
          :filter-form.sync="filterForm"
          :is-loading="sdsResultsIsLoading"
          @submit-search="submitSearch"
      ></sds-filter>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <sds-search-results
          v-if="showSearchResults"
          :sds-results="sdsResults"
          :sds-search-name="formFilterName"
          :is-loading="sdsResultsIsLoading"
          @search-languages="searchLanguages"
      ></sds-search-results>
    </b-col>
  </b-row>
  <b-row>
    <b-col ref="sds-languages-wrap">
      <sds-lang-availables
          ref="sds-lang-component"
          v-if="showSearchLanguages"
          :lang-availables="languagesCheck"
          :is-loading="languagesCheckIsLoading"
          @download-sds="downloadSds"
          @send-sds="sendSds"
          @anchor-to-feedback="anchorToFeedback"
      ></sds-lang-availables>
    </b-col>
  </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import FeedbackAlert from "@/components/feedback-alert.vue";
import AdwButton from "@/components/global-components/adw-button";
import ErrorManager from '@/services/errors-service/errors-manager';
import SdsFilter from "@/components/sds/sds-filter";
import SdsSearchResults from "@/components/sds/sds-search-results";
import SdsLangAvailables from "@/components/sds/sds-lang-availables";
import ProductService from "@/services/api-services/product-service";

export default {
  name: "SdsSearchCustomer",


  data() {
    return {
      formFilterName: 'searchCustomer',
      showSearchResults: false,
      sdsResults: [],
      sdsResultsIsLoading: false,
      showSearchLanguages: false,
      languagesCheck: {},
      languagesCheckIsLoading: false,
      currentRequest: null,
      filterForm: {
        site: null,
        serial: null,
        color: null,
        customerCode: null,
        orderCode: null,
        year: null,
      },
      feedbackStatus: null,
      feedbackMessage: "",
    }
  },

  components: {
    SdsFilter,
    SdsSearchResults,
    SdsLangAvailables,
    FeedbackAlert,
    AdwButton,
  },

  methods: {
    clearStatus() {
      this.feedbackStatus = null;
      this.feedbackMessage = "";
    },

    submitSearch() {
      this.showSearchResults = true;
      this.sdsResultsIsLoading = true;
      this.showSearchLanguages = false;
      this.$forceUpdate();

      ProductService.findSdsByFilters(this.filterForm)
          .then((result) => {
            this.sdsResults = result.data;
          }).catch((error) => {
        this.feedbackStatus = "FailDismissable"
        this.feedbackMessage = "Errore nella ricerca";//this.$i18n.t("UnauthorizedOrInvalid")
      }).finally(() => {
        this.sdsResultsIsLoading = false;
        this.$forceUpdate();
      })
    },

    searchLanguages(item) {
      console.log(item);
      this.showSearchLanguages = true;
      this.currentRequest = {
        site: item.site,
        batch: item.batch,
        serial: item.serial,
        color: item.color,
        recipe: item.recipe,
        version: item.version,
        isOldSds: item.isOldSds,
        isUsaOrCa: false
      }

      this.languagesCheckIsLoading = true;
      this.$forceUpdate();
      let element = this.$refs['sds-languages-wrap'];
      const top = element.offsetTop;
      window.scrollTo(0, top);


      ProductService.checkLanguages(this.currentRequest)
          .then((result) => {
            this.languagesCheck = result.data;
          }).catch((error) => {
        this.feedbackStatus = "FailDismissable"
        this.feedbackMessage = "Errore nel recupero delle lingue disponibili";//this.$i18n.t("UnauthorizedOrInvalid")
        this.languagesCheck = {};
      }).finally(() => {
        this.languagesCheckIsLoading = false;
        this.$forceUpdate();
      })
    },

    sendSds (sendSdsObj) {
      console.log("sendSds", sendSdsObj)
      this.languagesCheckIsLoading = true;
      this.$forceUpdate();

      ProductService.sendSds(
          Object.assign({emails: sendSdsObj.emails}, {language: sendSdsObj.language}, this.currentRequest)
      )
          .then((response) => {
            this.feedbackStatus = "Success";
            this.feedbackMessage = "sdsSentSuccess";
          }).catch((error) => {
        console.error(error)
        this.feedbackStatus = "FailDismissable"
        this.feedbackMessage = "sdsSentError";//this.$i18n.t("UnauthorizedOrInvalid")
      }).finally(() => {
        this.languagesCheckIsLoading = false;
        this.$refs['sds-lang-component'].closeModal();
        this.$forceUpdate();
      })
    },


    downloadSds(language) {
      this.languagesCheckIsLoading = true;
      this.$forceUpdate();

      ProductService.downloadSds(Object.assign({language}, this.currentRequest))
          .then((response) => {

            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
            link.download = 'SDS-' + this.currentRequest.serial + this.currentRequest.color + '-' + language + '.pdf'
            document.body.appendChild(link)

            // click the link to download the pdf
            link.click()

            // remove the link
            document.body.removeChild(link)

          }).catch((error) => {
        console.error(error)
        this.feedbackStatus = "FailDismissable"
        this.feedbackMessage = "sdsDownloadError";//this.$i18n.t("UnauthorizedOrInvalid")
      }).finally(() => {
        this.languagesCheckIsLoading = false;
        this.$refs['sds-lang-component'].closeModal();
        this.$forceUpdate();
      })
    },
    anchorToFeedback () {
      let element = this.$refs['sds-feedback'];
      let top = element ? element.offsetTop : 0;
      window.scrollTo(0, top);
    }
  },

  computed: {
    ...mapState({
      customerInfo: (state) => state.customer.customerinfo,
      userType: (state) => state.auth.user.type,
    }),

    isUserAgentOrCommercial() {
      return this.$store.getters.getUserRole === "AGENT" || this.$store.getters.getUserRole === "COMMERCIAL"
    },

  },
}
</script>
